.background {
    background-color: #282c34;
}

.column-header {
    background-color: #1565c0;;
    color: white;
    font-size: 1.2em;
}

.titled {
    text-transform: capitalize;
}

.sent-invoice {
    background-color: #4caf50;
    color: white;
}

.pending-invoice {
    background-color: #ec0c12;
    color: white;
}

.no-created-invoice {
    background-color: #ff9800;
    color: white;
}
